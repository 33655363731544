import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import { MyTextAreaProps } from './types';
import { errorLabel } from '../styles';

export default function MyTextArea(props: MyTextAreaProps) {
  const [field, { error, touched }] = useField(props.name);
  // !! zmienia zienną na bool !!meta.error
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event);
    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <Form.Group>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control as='textarea' isInvalid={touched && !!error} {...field} {...props} onChange={handleChange} />
      <Form.Control.Feedback type='invalid'>
        {touched && error ? <label style={errorLabel}>{error}</label> : null}
      </Form.Control.Feedback>
    </Form.Group>
  );
}
