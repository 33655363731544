import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Benefit } from '../../enums/benefits.enums';
import { useStore } from '../../store/store';
import CustomerDashboard from '../CustomerDashboard/CustomerDashboard';
import { SportCardHeader } from './SportCardHeader';
import UserPersonalDataLayout from '../../components/UserPersonalData/UserPersonalDataLayout';
import SportCardTabs from './SportCardTabs/SportCardTabs';

export default observer(() => {
  const { userStore } = useStore();

  const [hasBenefitApprovals, setHasBenefitApprovals] = useState<boolean>(true);

  useEffect(() => {
    userStore.hasRequiredApprovalsForBenefit(Benefit.SportCard).then((resp) => {
      setHasBenefitApprovals(resp);
    });
  }, []);

  return (
    <>
      <CustomerDashboard>
        <div className={'tw-mx-auto tw-max-w-2xl'}>
          <SportCardHeader />
          <div>
            <UserPersonalDataLayout>
              <SportCardTabs />
            </UserPersonalDataLayout>
          </div>
        </div>
      </CustomerDashboard>
    </>
  );
});
