import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './theme/styles.scss';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import reportWebVitals from './reportWebVitals';
import { store, StoreContext } from './store/store';
import App from './App';
import { extendTailwindMerge } from 'tailwind-merge';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';

Sentry.init({
  dsn: 'https://6991114035510553bccceb4571d46374@o4508012254396416.ingest.de.sentry.io/4508012580372560',
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const history = createBrowserHistory();

export const twMerge = extendTailwindMerge({
  prefix: 'tw-',
});

const checkRefresh = () => {
  const lastRefreshTime = window.localStorage.getItem('lastRefreshTime');
  const currentTime = new Date().getTime();
  const oneHour = 60 * 60 * 1000; // milliseconds in one hour

  // Check if lastRefreshTime is more than an hour ago
  if (!lastRefreshTime || currentTime - Number(lastRefreshTime) > oneHour) {
    window.localStorage.setItem('lastRefreshTime', String(currentTime));
    // eslint-disable-next-line no-self-assign
    window.location.href = window.location.href;
    return true;
  }
  return false;
};

const initializeApp = () => {
  const onApplicationDidLaunchFromLink = (eventData: URLOpenListenerEvent) => {
    try {
      const url = new URL(eventData.url);
      const path = url.pathname + url.search + url.hash;
      history.push(path);
    } catch (error) {
      toast.error('Wystąpił błąd');
    }
  };

  const startApp = () => {
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <StoreContext.Provider value={store}>
        <Router history={history}>
          <App />
        </Router>
      </StoreContext.Provider>,
    );

    if (Capacitor.isNativePlatform()) {
      CapacitorApp.addListener('appUrlOpen', onApplicationDidLaunchFromLink);
    }
  };

  if (Capacitor.isNativePlatform()) {
    document.addEventListener('deviceready', startApp, false);
  } else {
    startApp();
  }

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
};

if (Capacitor.isNativePlatform()) {
  if (!checkRefresh()) {
    initializeApp();
  }
} else {
  initializeApp();
}
