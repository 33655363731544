import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import { ErrorMessage, Form, Formik } from 'formik';
import MyTextMaskedInput from '../../FormControls/MyTextMaskedInput/MyTextMaskedInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { initialValues, validationSchema } from './index';
import { LoanContractAddressData } from '../../../types/loans';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { toast } from 'react-toastify';
import * as styles from './styles';
import { history } from '../../../index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { PathRoute } from '../../../constants/pathRoute/Route';
import MyCheckbox from '../../FormControls/MyCheckbox/MyCheckbox';
import { Link, useParams } from 'react-router-dom';
import CustomerDashboard from '../../../containers/CustomerDashboard/CustomerDashboard';

export default observer(() => {
  const { loansStore } = useStore();
  const [loading, setLoading] = useState<boolean>(true);
  const [contract, setContract] = useState<LoanContractAddressData>(initialValues);
  const [differentCorrespondenceState, setDifferentCorrespondence] = useState<boolean>(false);
  const { id }: any = useParams();
  const toggleDifferentCorrespondence = () => {
    setDifferentCorrespondence(!differentCorrespondenceState);
  };
  useEffect(() => {
    if (id == 0 || id == undefined || id == null) {
      if (loansStore.availableBenefitId != null) {
        loansStore.getUserLoanContractId(loansStore.availableBenefitId).then((resp) => {
          // @ts-ignore
          getContractData(resp);
        });
      }
    } else {
      getContractData(id);
    }
  }, []);

  const getContractData = (id: number) => {
    loansStore
      .getLoanContractAddressData(id)
      .then((resp) => {
        setLoading(false);
        const respContract = {
          id: resp ? resp.id : id,
          street: resp ? resp.street : '',
          houseNo: resp ? resp.houseNo : '',
          flatNo: resp ? resp.flatNo : '',
          place: resp ? resp.place : '',
          postCode: resp ? resp.postCode : '',
          correspondenceStreet: resp ? resp.correspondenceStreet : '',
          correspondenceHouseNo: resp ? resp.correspondenceHouseNo : '',
          correspondenceFlatNo: resp ? resp.correspondenceFlatNo : '',
          correspondencePlace: resp ? resp.correspondencePlace : '',
          correspondencePostCode: resp ? resp.correspondencePostCode : '',
          differentCorrespondece: resp ? resp.differentCorrespondece : differentCorrespondenceState,
        };
        setDifferentCorrespondence(respContract.differentCorrespondece);
        setContract(respContract);
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <LoadingComponent content='Ładowanie...' />
      ) : (
        <CustomerDashboard>
          <div className='col-md-6 offset-md-3'>
            <div className='container-fluid' id='Loans'>
              <div className='d-flex justify-content-center align-items-center'>
                <div style={styles.goBackLink}>
                  <Link to={PathRoute.USER_CONTRACT_LOAN + '/' + id}>
                    <FontAwesomeIcon color='#626d80' icon={faCaretLeft} size='2x' />
                  </Link>
                </div>
                <div style={styles.header}>
                  <h2 style={styles.headingText}>Wypłata od ręki</h2>
                  <p style={styles.subheadingText}>Dane klienta</p>
                </div>
              </div>
              <p style={styles.paragraphText}>Wprowadź swoje dane, niezbędne do zawarcia umowy ramowej</p>
              <br />
              <p style={styles.formTitle}>Twój adres</p>
              <Formik
                enableReinitialize
                initialValues={{
                  id: id,
                  street: contract ? contract.street : '',
                  houseNo: contract ? contract.houseNo : '',
                  flatNo: contract ? contract.flatNo : '',
                  place: contract ? contract.place : '',
                  postCode: contract ? contract.postCode : '',
                  correspondenceStreet: contract ? contract.correspondenceStreet : '',
                  correspondenceHouseNo: contract ? contract.correspondenceHouseNo : '',
                  correspondenceFlatNo: contract ? contract.correspondenceFlatNo : '',
                  correspondencePlace: contract ? contract.correspondencePlace : '',
                  correspondencePostCode: contract ? contract.correspondencePostCode : '',
                  differentCorrespondece: contract ? contract.differentCorrespondece : differentCorrespondenceState,
                  error: null,
                }}
                onSubmit={(
                  {
                    id,
                    street,
                    houseNo,
                    flatNo,
                    place,
                    postCode,
                    correspondenceStreet,
                    correspondenceHouseNo,
                    correspondenceFlatNo,
                    correspondencePlace,
                    correspondencePostCode,
                  },
                  { setErrors, setFieldError, setSubmitting },
                ) =>
                  loansStore
                    .saveLoanContractAddressData(
                      id,
                      street,
                      houseNo,
                      flatNo,
                      place,
                      postCode,
                      correspondenceStreet,
                      correspondenceHouseNo,
                      correspondenceFlatNo,
                      correspondencePlace,
                      correspondencePostCode,
                      differentCorrespondenceState,
                    )
                    .then((resp) => {
                      history.push(PathRoute.EMPLOYMENT_CONTRACT_LOAN + '/' + resp);
                    })
                    .catch((error) => {
                      // @ts-ignore
                      if (Array.isArray(error)) {
                        error.forEach((element) => {
                          const fieldName = element.fieldName.charAt(0).toLowerCase() + element.fieldName.slice(1);
                          // @ts-ignore
                          setFieldError(fieldName, element.message);
                          //setFieldTouched(fieldName);
                          setSubmitting(false);
                        });
                      } else {
                        setErrors({ error: error });
                        setSubmitting(false);
                      }
                    })
                }
                validationSchema={validationSchema}>
                {({ handleSubmit, errors }) => (
                  <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                    <MyTextInput label={'Ulica'} name={'street'} style={styles.marginBottomField} />
                    <MyTextInput label='Numer domu' name={`houseNo`} style={styles.marginBottomField} />
                    <MyTextInput label='Numer mieszkania' name={`flatNo`} style={styles.marginBottomField} />
                    <MyTextInput label='Miejscowość' name={`place`} style={styles.marginBottomField} />
                    <MyTextMaskedInput
                      label='Kod pocztowy'
                      mask={MaskHelpers.postCode}
                      name={`postCode`}
                      style={styles.marginBottomField}
                    />
                    <MyCheckbox
                      id='differentCorrespondence'
                      label='Inny adres korespondencyjny'
                      name={`differentCorrespondence`}
                      onChange={() => toggleDifferentCorrespondence()}
                      checked={differentCorrespondenceState}
                      type='checkbox'
                    />
                    {differentCorrespondenceState && (
                      <div>
                        <p style={styles.formTitle}>Adres korespondencyjny</p>
                        <MyTextInput label={'Ulica'} name={'correspondenceStreet'} style={styles.marginBottomField} />
                        <MyTextInput
                          label='Numer domu'
                          name={`correspondenceHouseNo`}
                          style={styles.marginBottomField}
                        />
                        <MyTextInput
                          label='Numer mieszkania'
                          name={`correspondenceFlatNo`}
                          style={styles.marginBottomField}
                        />
                        <MyTextInput
                          label='Miejscowość'
                          name={`correspondencePlace`}
                          style={styles.marginBottomField}
                        />
                        <MyTextMaskedInput
                          label='Kod pocztowy'
                          mask={MaskHelpers.postCode}
                          name={`correspondencePostCode`}
                          style={styles.marginBottomField}
                        />
                      </div>
                    )}
                    <ErrorMessage name='error' render={() => <label>{errors.error}</label>} />
                    <div
                      style={{
                        marginTop: '15px',
                        display: 'flex',
                        justifyContent: 'right',
                      }}>
                      <Button className='btn-full-width' type='submit' variant='primary'>
                        Dalej
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </CustomerDashboard>
      )}
    </>
  );
});
