import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useStore } from '../../../store/store';
import { Form, Formik } from 'formik';
import MySelectInput from '../../FormControls/MySelectInput/MySelectInput';
import MyTextInput from '../../FormControls/MyTextInput/MyTextInput';
import MaskHelpers from '../../../helpers/MaskHelpers';
import { Countries } from '../../../enums/countries.enums';
import { initialValues, validationSchema } from './index';
import { toast } from 'react-toastify';
import { FormikScrollToFieldError } from '../../FormikScrollToFieldError/FormikScrollToFieldError';
import LoadingComponent from '../../Loadings/LoadingComponent/LoadingComponent';
import { handleErrorResponse } from '../../../utils/apiErrorHandle';
import { TripInsuranceBasicFormValues, TripInsuranceFormValues } from '../../../types/tripInsurance';
import MyCheckbox from '../../FormControls/MyCheckbox/MyCheckbox';
import MyRadioButton from '../../FormControls/MyRadioButton/MyRadioButton';
import DateHelpers from '../../../helpers/DateHelpers';
import MyTextArea from '../../FormControls/MyTextArea/MyTextArea';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { MySelectInputOptions } from '../../FormControls/MySelectInput/types';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { LeadCampaignType } from '../../../types/leads';

export default observer((props: { values: TripInsuranceBasicFormValues; isSent: boolean }) => {
  const { leadStore, userStore } = useStore();

  const [loading, setLoading] = useState<boolean>(true);
  const [isSent, setIsSent] = useState<boolean>(props.isSent);
  const [isConfirmView, setIsConfirmView] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<TripInsuranceFormValues>(initialValues);
  const countriesOptions: MySelectInputOptions[] = Countries.map((country) => ({
    value: country.value,
    label: country.label,
  }));

  const getLeadCampaignHumanName = (leadCampaignType: LeadCampaignType): string => {
    switch (leadCampaignType) {
      case 'car':
        return 'OC_AC Samochodu';
      case 'property':
        return 'Ubezpieczenie majątkowe';
      case 'trip':
        return 'Ubezpieczenie turystyczne';
      case 'simNau':
        return 'Sim Nau';
      default:
        return '';
    }
  };

  const handleChangeValue = (name: string, value: any) => {
    if (value === 'true' || value === 'false') {
      const booleanValue = value === 'true' ? true : value === 'false' ? false : value;
      setFormValues({ ...formValues, [name]: booleanValue });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };
  const handleCoInsuredChangeValue = (name: string, index: number, value: any) => {
    const newFormValues = { ...formValues };
    newFormValues.coInsuredPersons[index] = { ...newFormValues.coInsuredPersons[index], [name]: value };
    setFormValues(newFormValues);
  };
  const handleInsuranceStartDateChange = (startYear?: string, startMonth?: string) => {
    if (startYear) {
      const newFormValues = { ...formValues, insuranceStartYear: parseInt(startYear) };
      if (startYear && formValues.insuranceStartDay && formValues.insuranceStartMonth) {
        const monthLength = DateHelpers.getMonthLength(
          newFormValues.insuranceStartYear,
          formValues.insuranceStartMonth,
        );
        if (formValues.insuranceStartDay > monthLength) {
          newFormValues.insuranceStartDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
    if (startMonth) {
      if (parseInt(startMonth) <= 0 || parseInt(startMonth) >= 13) {
        startMonth = formValues.insuranceStartMonth?.toString() ?? '1';
      }
      const newFormValues = { ...formValues, insuranceStartMonth: parseInt(startMonth) };
      if (formValues.insuranceStartYear && startMonth && formValues.insuranceStartDay) {
        const monthLength = DateHelpers.getMonthLength(
          formValues.insuranceStartYear,
          newFormValues.insuranceStartMonth,
        );
        if (formValues.insuranceStartDay > monthLength) {
          newFormValues.insuranceStartDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
  };
  const handleInsuranceEndDateChange = (endYear?: string, endMonth?: string) => {
    if (endYear) {
      const newFormValues = { ...formValues, insuranceEndYear: parseInt(endYear) };
      if (endYear && formValues.insuranceEndDay && formValues.insuranceEndMonth) {
        const monthLength = DateHelpers.getMonthLength(newFormValues.insuranceEndYear, formValues.insuranceEndMonth);
        if (formValues.insuranceEndDay > monthLength) {
          newFormValues.insuranceEndDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
    if (endMonth) {
      if (parseInt(endMonth) <= 0 || parseInt(endMonth) >= 13) {
        endMonth = formValues.insuranceEndMonth?.toString() ?? '1';
      }
      const newFormValues = { ...formValues, insuranceEndMonth: parseInt(endMonth) };
      if (formValues.insuranceEndYear && endMonth && formValues.insuranceEndDay) {
        const monthLength = DateHelpers.getMonthLength(formValues.insuranceEndYear, newFormValues.insuranceEndMonth);
        if (formValues.insuranceEndDay > monthLength) {
          newFormValues.insuranceEndDay = monthLength;
        }
      }
      setFormValues(newFormValues);
    }
  };
  const addCoInsured = () => {
    const newCoInsured = {
      firstName: '',
      lastName: '',
      pesel: '',
      email: '',
      phone: '',
      addressStreet: '',
      addressHouseNumber: '',
      addressFlatNumber: '',
      addressPostCode: '',
      addressPlace: '',
      obtainingDrivingLicenseYear: undefined,
      obtainingDrivingLicenseMonth: undefined,
      obtainingDrivingLicenseDay: undefined,
    };
    const newCoInsuredPersons = [...formValues.coInsuredPersons, newCoInsured];
    setFormValues({ ...formValues, coInsuredPersons: newCoInsuredPersons });
  };

  const removeCoInsured = (index: number) => {
    const newCoInsuredPersons = formValues.coInsuredPersons.filter((_, i) => i !== index);
    setFormValues({ ...formValues, coInsuredPersons: newCoInsuredPersons });
  };

  useEffect(() => {
    userStore
      .getUserPesel()
      .then((pesel) => {
        formValues.pesel = pesel?.toString() ?? '';
      })
      .finally(() => {
        formValues.fileId = props.values.fileId;
        formValues.firstName = props.values.firstName;
        formValues.lastName = props.values.lastName;
        formValues.phone = props.values.phone;
        formValues.campaignTypeHumanName = getLeadCampaignHumanName(formValues.campaignType);
        const user = userStore.userData;
        formValues.email = user?.email ?? '';
        setFormValues(formValues);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {isSent ? (
        <div>
          <div className='tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
            Dziękujemy za zainteresowanie naszą ofertą.
          </div>

          <div className='tw-my-8 tw-text-center tw-text-base tw-text-nau-green-dark'>
            Jesteśmy w trakcie przetwarzania Twojego wniosku.
            <br />W razie pytań zapraszany do kontaktu:
          </div>

          <div className='tw-my-8 tw-text-center tw-text-base tw-text-nau-green-dark'>
            numer telefonu - <b>22 696 72 70</b>
            <br />
            adres e-mail - <b>bok@nau.pl</b>
          </div>
        </div>
      ) : (
        <>
          {isConfirmView ? (
            <Formik
              enableReinitialize
              initialValues={formValues}
              onSubmit={(values, { setErrors, setFieldError, setSubmitting }) => {
                leadStore
                  .sendTripInsuranceLead(formValues)
                  .then(() => {
                    setIsSent(true);
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    handleErrorResponse(err, setFieldError);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
              validationSchema={validationSchema}>
              {({ handleSubmit, errors, values, isSubmitting }) => (
                <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                  <div className='tw-my-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
                    Prosimy o potwierdzenie informacji kontaktowych, abyśmy mogli skontaktować się w sprawie oferty
                  </div>

                  <MyTextInput
                    label='Email'
                    name='email'
                    onChange={(e) => handleChangeValue('email', e.target.value)}
                  />
                  <MyTextInput
                    label='Numer telefonu'
                    name='phone'
                    onChange={(e) => handleChangeValue('phone', e.target.value)}
                    mask={MaskHelpers.phone}
                  />
                  <div className='d-flex justify-content-center'>
                    <Button type='submit' variant='primary'>
                      Wyślij
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          ) : (
            <>
              <LoadingComponent content='Ładowanie...' visible={loading} />
              <Formik
                enableReinitialize
                initialValues={formValues}
                onSubmit={(values, { setErrors, setFieldError, setSubmitting }) => {
                  setIsConfirmView(true);
                }}
                validationSchema={validationSchema}>
                {({ handleSubmit, errors, values, isSubmitting }) => (
                  <Form autoComplete='off' className='ui form' onSubmit={handleSubmit}>
                    <div className={'d-flex flex-column gap-2'}>
                      <p className='tw-text-lg tw-text-teal-700'>Data wyjazdu</p>
                      <div className='tw-flex'>
                        <div className='tw-w-1/3 tw-pr-1.5'>
                          <MyTextInput
                            label='Rok'
                            name='insuranceStartYear'
                            type='number'
                            min={new Date().getFullYear().toString()}
                            onChange={(e) => handleInsuranceStartDateChange(e.target.value, undefined)}
                          />
                        </div>
                        <div className='tw-w-1/3 tw-px-1.5'>
                          <MyTextInput
                            label='Miesiąc'
                            name='insuranceStartMonth'
                            type='number'
                            min='1'
                            max='12'
                            value={formValues.insuranceStartMonth}
                            onChange={(e) => handleInsuranceStartDateChange(undefined, e.target.value)}
                          />
                        </div>
                        <div className='tw-w-1/3 tw-pl-1.5'>
                          <MyTextInput
                            label='Dzień'
                            name='insuranceStartDay'
                            type='number'
                            min='1'
                            max={
                              formValues.insuranceStartMonth && formValues.insuranceStartYear
                                ? DateHelpers.getMonthLength(
                                    formValues.insuranceStartYear,
                                    formValues.insuranceStartMonth,
                                  ).toString()
                                : '31'
                            }
                            onChange={(e) => handleChangeValue('insuranceStartDay', e.target.value)}
                          />
                        </div>
                      </div>
                      <p className='tw-text-lg tw-text-teal-700'>Data powrotu</p>
                      <div className='tw-flex'>
                        <div className='tw-w-1/3 tw-pr-1.5'>
                          <MyTextInput
                            label='Rok'
                            name='insuranceEndYear'
                            type='number'
                            min={new Date().getFullYear().toString()}
                            onChange={(e) => handleInsuranceEndDateChange(e.target.value, undefined)}
                          />
                        </div>
                        <div className='tw-w-1/3 tw-px-1.5'>
                          <MyTextInput
                            label='Miesiąc'
                            name='insuranceEndMonth'
                            type='number'
                            min='1'
                            max='12'
                            value={formValues.insuranceEndMonth}
                            onChange={(e) => handleInsuranceEndDateChange(undefined, e.target.value)}
                          />
                        </div>
                        <div className='tw-w-1/3 tw-pl-1.5'>
                          <MyTextInput
                            label='Dzień'
                            name='insuranceEndDay'
                            type='number'
                            min='1'
                            max={
                              formValues.insuranceEndMonth && formValues.insuranceEndYear
                                ? DateHelpers.getMonthLength(
                                    formValues.insuranceEndYear,
                                    formValues.insuranceEndMonth,
                                  ).toString()
                                : '31'
                            }
                            onChange={(e) => handleChangeValue('insuranceEndDay', e.target.value)}
                          />
                        </div>
                      </div>
                      <p className='tw-text-lg tw-text-teal-700'>Kraj wyjazdu:</p>
                      <div className='tw-flex'>
                        <MySelectInput
                          name='country'
                          value={formValues.country}
                          options={countriesOptions}
                          containerClassName='tw-w-full'
                          onChange={(e) => handleChangeValue('country', e)}
                        />
                      </div>
                      <p className='tw-text-lg tw-text-teal-700'>Dane osobowe:</p>
                      <div className='tw-flex'>
                        <div className='tw-w-1/2 tw-pr-1.5'>
                          <MyTextInput
                            label='Imię'
                            name='firstName'
                            onChange={(e) => handleChangeValue('firstName', e.target.value)}
                          />
                        </div>
                        <div className='tw-w-1/2 tw-pl-1.5'>
                          <MyTextInput
                            label='Nazwisko'
                            name='lastName'
                            onChange={(e) => handleChangeValue('lastName', e.target.value)}
                          />
                        </div>
                      </div>
                      <MyTextInput
                        label='PESEL'
                        name='pesel'
                        onChange={(e) => handleChangeValue('pesel', e.target.value)}
                        mask={MaskHelpers.pesel}
                      />
                      <MyTextInput
                        label='Email'
                        name='email'
                        onChange={(e) => handleChangeValue('email', e.target.value)}
                      />
                      <MyTextInput
                        label='Numer telefonu'
                        name='phone'
                        onChange={(e) => handleChangeValue('phone', e.target.value)}
                        mask={MaskHelpers.phone}
                      />
                      <div className='tw-flex'>
                        <div className='tw-w-1/2 tw-pr-1.5'>
                          <MyTextInput
                            label='Ulica'
                            name='addressStreet'
                            onChange={(e) => handleChangeValue('addressStreet', e.target.value)}
                          />
                        </div>
                        <div className='tw-w-1/4 tw-px-1.5'>
                          <MyTextInput
                            label='Numer domu'
                            name='addressHouseNumber'
                            onChange={(e) => handleChangeValue('addressHouseNumber', e.target.value)}
                          />
                        </div>
                        <div className='tw-w-1/4 tw-pl-1.5'>
                          <MyTextInput
                            label='Numer mieszkania'
                            name='addressFlatNumber'
                            onChange={(e) => handleChangeValue('addressFlatNumber', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='tw-flex'>
                        <div className='tw-w-1/2 tw-pr-1.5'>
                          <MyTextInput
                            label='Kod pocztowy'
                            name='addressPostCode'
                            onChange={(e) => handleChangeValue('addressPostCode', e.target.value)}
                            mask={MaskHelpers.postCode}
                          />
                        </div>
                        <div className='tw-w-1/2 tw-pl-1.5'>
                          <MyTextInput
                            label='Miejscowość'
                            name='addressPlace'
                            onChange={(e) => handleChangeValue('addressPlace', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='cursor-pointer tw-my-3 tw-flex tw-items-center ' onClick={addCoInsured}>
                        <FontAwesomeIcon color='#00635c' icon={faCirclePlus} size='2x' />
                        <div className='tw-w-2/3 tw-pl-2'>Dodaj dane kolejnej osoby wyjeżdżającej</div>
                      </div>
                      {formValues.coInsuredPersons.map((coInsured, index) => (
                        <div className='tw-mb-2 tw-flex' key={index}>
                          <div className='tw-w-1/2 tw-pr-1.5'>
                            <MyTextInput
                              label='Imię'
                              name={`coInsuredPersons[${index}].firstName`}
                              onChange={(e) => handleCoInsuredChangeValue(`firstName`, index, e.target.value)}
                            />
                          </div>
                          <div className='tw-w-1/2 tw-pl-1.5'>
                            <MyTextInput
                              label='Nazwisko'
                              name={`coInsuredPersons[${index}].lastName`}
                              onChange={(e) => handleCoInsuredChangeValue(`lastName`, index, e.target.value)}
                            />
                          </div>
                          <div className='tw-ml-2'>
                            <MyTextInput
                              label='PESEL'
                              name={`coInsuredPersons[${index}].pesel`}
                              onChange={(e) => handleCoInsuredChangeValue(`pesel`, index, e.target.value)}
                              mask={MaskHelpers.pesel}
                            />
                          </div>
                          <div
                            className='cursor-pointer tw-ml-2 tw-flex tw-items-end'
                            onClick={() => removeCoInsured(index)}>
                            <FontAwesomeIcon color='#00635c' icon={faCircleMinus} size='2x' />
                          </div>
                        </div>
                      ))}
                      <div className='tw-flex tw-items-center tw-justify-between'>
                        <p className='tw-text-lg tw-text-teal-700'>
                          Czy chcesz rozszerzyć ubezpieczenie o choroby przewlekłe?
                        </p>
                        <div
                          className={'tw-flex tw-cursor-pointer tw-items-center tw-justify-center'}
                          style={
                            {
                              background: '#00A754',
                              height: '30px',
                              minWidth: '30px',
                              borderRadius: '30px',
                              color: '#fff',
                            } as React.CSSProperties
                          }
                          data-tooltip-delay-show={200}
                          data-tooltip-id='tooltip-disease-extension'>
                          ?
                        </div>
                      </div>
                      <Tooltip
                        id='tooltip-disease-extension'
                        place={'bottom-end'}
                        style={
                          { maxWidth: '500px', background: '#00A754', borderRadius: '15px' } as React.CSSProperties
                        }>
                        <p className={``}>
                          <b>choroba przewlekła</b> – stan chorobowy charakteryzujący się powolnym rozwojem oraz
                          długookresowym przebiegiem choroby, leczony stale lub okresowo przed datą zakupu ubezpieczenia
                          na czas wyjazdu zagranicznego
                        </p>
                      </Tooltip>
                      <MyRadioButton
                        name='diseaseExtension'
                        value={formValues.diseaseExtension}
                        options={[
                          { value: true, label: 'Tak' },
                          { value: false, label: 'Nie' },
                        ]}
                        column={true}
                        onChange={(e: boolean) => handleChangeValue('diseaseExtension', e)}
                      />
                      <div className='tw-flex tw-items-center tw-justify-between'>
                        <p className='tw-text-lg tw-text-teal-700'>
                          Czy chcesz rozszerzyć ubezpieczenie o wyczynowe uprawianie sportu?
                        </p>
                        <div
                          className={'tw-flex tw-cursor-pointer tw-items-center tw-justify-center'}
                          style={
                            {
                              background: '#00A754',
                              height: '30px',
                              minWidth: '30px',
                              borderRadius: '30px',
                              color: '#fff',
                            } as React.CSSProperties
                          }
                          data-tooltip-delay-show={200}
                          data-tooltip-id='tooltip-sport-extension'>
                          ?
                        </div>
                      </div>
                      <Tooltip
                        id='tooltip-sport-extension'
                        place={'bottom-end'}
                        style={
                          { maxWidth: '500px', background: '#00A754', borderRadius: '15px' } as React.CSSProperties
                        }>
                        <p className={``}>
                          <b>wyczynowe uprawianie sportu</b> – podejmowana dobrowolnie forma aktywności fizycznej
                          Ubezpieczonego polegająca na uprawianiu dyscyplin sportu poprzez uczestniczenie w treningach,
                          zawodach, zgrupowaniach, obozach kondycyjnych lub szkoleniowych, w celu uzyskania, w drodze
                          indywidualnej lub zbiorowej rywalizacji, maksymalnych wyników sportowych przez osoby będące
                          członkami klubów, związków i organizacji sportowych; a także - uprawianie sportów w celach
                          zarobkowych
                        </p>
                      </Tooltip>
                      <MyRadioButton
                        name='sportExtension'
                        value={formValues.sportExtension}
                        options={[
                          { value: true, label: 'Tak' },
                          { value: false, label: 'Nie' },
                        ]}
                        column={true}
                        onChange={(e: boolean) => handleChangeValue('sportExtension', e)}
                      />

                      <MyTextArea
                        label='Masz uwagi?'
                        placeholder='Wpisz je tutaj...'
                        rows={5}
                        name='comments'
                        onChange={(e) => handleChangeValue('comments', e.target.value)}
                      />
                      <div className='d-flex justify-content-center'>
                        <Button type='submit' disabled={isSubmitting} variant='primary'>
                          {isSubmitting ? (
                            <>
                              <Spinner animation='border' size='sm' />
                              Wysyłanie...
                            </>
                          ) : (
                            'Wyślij'
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </>
      )}
    </>
  );
});
