export enum SportCardSubscriptionPlan {
  OptimalEmployee = 1,
  OptimalCompanion = 2,
  OptimalChild = 3,
  OptimalChildPool = 4,
  ComfortEmployee = 5,
  ComfortCompanion = 6,
  ComfortChild = 7,
  ComfortChildPool = 8,
}

export enum SportCardStatus {
  Active = 1,
  Resignation = 2,
}
