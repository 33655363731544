import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import agent from '../../api/agent';
import MyTextInput from '../FormControls/MyTextInput/MyTextInput';
import MySelectInput from '../FormControls/MySelectInput/MySelectInput';
import MaskHelpers from '../../helpers/MaskHelpers';
import { InstitutionWithAddressFromSopDTO } from '../../types/institution';

interface IInstitutionAddressFormProps {
  postCodeFormikName: string;
  cityFormikName: string;
  streetFormikName: string;
  institutionSopIdFormikName: string;
  institutionFullNameFormikName: string;
  regonFormikName: string;
  placeholderInsteadOfLabel?: boolean;
}

export const InstitutionSearchByAddressForm = (props: IInstitutionAddressFormProps) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const {
    postCodeFormikName,
    cityFormikName,
    streetFormikName,
    institutionFullNameFormikName,
    institutionSopIdFormikName,
    regonFormikName,
  } = props;

  const [areCitiesLoading, setAreCitiesLoading] = useState<boolean>(false);
  const [citiesFromApi, setCitiesFromApi] = useState<string[]>([]);
  const [institutions, setInstitutions] = useState<InstitutionWithAddressFromSopDTO[]>([]);

  const loadCities = (zipCode: string, place: string, onlyCities: boolean) => {
    setAreCitiesLoading(true);
    if (!zipCode && !place) {
      setAreCitiesLoading(false);
      return;
    }
    if (zipCode.length > 0 && zipCode.length <= 2) {
      setAreCitiesLoading(false);
      return;
    }
    if (place.length > 0 && place.length <= 2) {
      setAreCitiesLoading(false);
      return;
    }
    agent.Institution.getInstitutionsByAddressFromSop(zipCode, place)
      .then((resp) => {
        setCitiesFromApi([...new Set(resp.map((obj) => obj.place))]);
        if (!onlyCities) {
          setInstitutions(resp);
        }
      })
      .finally(() => {
        setAreCitiesLoading(false);
      });
  };

  const cleanStreet = (street: string) => {
    return street.replaceAll('ul.', '').replaceAll('Ul.', '').trim();
  };

  return (
    <>
      <div className={'tw-mt-4 tw-flex tw-flex-col tw-justify-center tw-gap-4'}>
        <div className={'tw-flex tw-w-full tw-justify-center tw-gap-4 tw-font-normal'}>
          <MyTextInput
            mask={MaskHelpers.postCode}
            name='zipCode'
            onChange={(value) => {
              const newZipCode = value.target.value.replaceAll('_', '');
              if (
                value.target.value.replaceAll('_', '').replaceAll('-', '') !=
                values[postCodeFormikName].replaceAll('_', '').replaceAll('-', '')
              ) {
                setFieldValue(cityFormikName, '');
                setFieldValue(streetFormikName, '');
                setFieldValue(institutionSopIdFormikName, 0);
                loadCities(newZipCode, '', false);
              }
            }}
            placeholder='Kod pocztowy'
            wrapperClassName={'tw-max-w-[150px]'}
          />
          <div className={'tw-grow'}>
            <MySelectInput
              bodyPortal={true}
              className={'tw-w-full'}
              isLoading={areCitiesLoading}
              name={cityFormikName}
              onChange={(val) => {
                setFieldValue(streetFormikName, '');
                setFieldValue(institutionSopIdFormikName, 0);
                loadCities(values[postCodeFormikName].replaceAll('_', ''), val as string, false);
              }}
              onInputChange={(val) => {
                loadCities(values[postCodeFormikName].replaceAll('_', ''), val as string, true);
              }}
              options={citiesFromApi.map((city) => ({ value: city, label: city }))}
              placeholder={'Wpisz miasto...'}
              value={values[cityFormikName]}
            />
          </div>
        </div>
        {institutions && institutions.length > 0 && (
          <div className={'tw-flex tw-w-full tw-flex-col   tw-gap-4 tw-font-normal'}>
            <div className={'tw-w-full'}>
              <MySelectInput
                bodyPortal={true}
                className={'tw-w-full'}
                isClearable={true}
                isLoading={areCitiesLoading}
                name={streetFormikName}
                onChange={() => setFieldValue('institutionSopId', 0)}
                options={[...new Set(institutions.map((obj) => cleanStreet(obj.street)))].map((street) => ({
                  value: street,
                  label: street,
                }))}
                placeholder={'Ulica'}
                value={values[streetFormikName]}
              />
            </div>
            <div className={'tw-w-full'}>
              <MySelectInput
                bodyPortal={true}
                className={'tw-w-full'}
                isClearable={true}
                isLoading={areCitiesLoading}
                name={institutionSopIdFormikName}
                onChange={(value) => {
                  const selectedInstitution = institutions.find((obj) => obj.id == value);
                  setFieldValue(regonFormikName, selectedInstitution?.regon ?? '');
                  setFieldValue(institutionFullNameFormikName, selectedInstitution?.fullName ?? '');
                }}
                options={institutions
                  .filter(
                    (obj) =>
                      !values[streetFormikName] || cleanStreet(obj.street) == cleanStreet(values[streetFormikName]),
                  )
                  .filter(
                    (obj, index, self) =>
                      index === self.findIndex((t) => t.id === obj.id && t.fullName === obj.fullName),
                  )
                  .map((obj) => ({ value: obj.id, label: obj.fullName }))}
                placeholder={'Placówka'}
                value={values[institutionSopIdFormikName]}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
