import React, { useState } from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import { useStore } from '../../../store/store';
import CallBooking from '../../../components/CallBooking/CallBooking';
import MyButton from '../../../components/Buttons/MyButton/MyButton';
import { Image } from 'react-bootstrap';
import { twMerge } from 'tailwind-merge';
import PhoneIcon from '../../../components/Icons/PhoneIcon';
import NauPlainIcon from '../../../components/Icons/NauPlainIcon';

export const SimNauPage = () => {
  const { userStore } = useStore();
  const [isCallBookView, setIsCallBookView] = useState<boolean>(false);

  return (
    <CustomerDashboard>
      <div className='tw-mx-auto md:tw-w-1/2'>
        <div
          className={
            'tw-h-[100px] tw-rounded-t-[10px] tw-bg-gradient-to-r tw-from-nau-green-dark tw-to-nau-green-light'
          }>
          <div
            className={twMerge(
              'tw-flex tw-h-full tw-items-center tw-justify-center tw-px-1 tw-text-center tw-text-white',
            )}>
            <PhoneIcon className={'tw-mr-6'} />
            <NauPlainIcon className={''} />
          </div>
        </div>

        <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-p-6  tw-shadow-md'>
          {isCallBookView ? (
            <CallBooking leadCampaignType={'simNau'} />
          ) : (
            <>
              <p className='tw-text-base'>
                Chcesz płacić mniej?! SIM NAU to rozwiązanie dla Ciebie. Skorzystaj z jednej z najniższych cen na rynku!{' '}
                <span className={'tw-font-bold tw-text-nau-sea-green'}>Już od 8,72 zł za mies.</span>
              </p>
              <h2 className='tw-mt-8 tw-text-xl tw-font-bold tw-text-nau-sea-green'>Jak to działa?</h2>
              <p className='tw-text-base'>
                Udostępnimy Ci kartę SIM w sieci PLAY na 12 mies. Masz nielimitowane rozmowy i SMS/MMS.
              </p>

              <MyButton
                className={'tw-mt-8 tw-w-full'}
                onClick={() => setIsCallBookView(true)}
                type='button'
                variant={'greenDark'}>
                Zamów rozmowę
              </MyButton>
              <div className={'tw-mx-auto tw-my-8 tw-flex tw-flex-col tw-items-center tw-gap-4 md:tw-w-1/2'}>
                <Image className={'tw-bg-white'} src='/assets/nauMobile/simNauKafle.svg' />
              </div>
              <MyButton
                className={' tw-w-full'}
                onClick={() => setIsCallBookView(true)}
                type='button'
                variant={'greenDark'}>
                Zamów rozmowę
              </MyButton>
            </>
          )}
        </div>
      </div>
    </CustomerDashboard>
  );
};
