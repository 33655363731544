import React from 'react';
import { ISportCardDto } from '../../types/sportCard';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import agent from '../../api/agent';
import { toast } from 'react-toastify';
import { SportCardStatus } from '../../enums/sportCard.enums';
import { history, twMerge } from '../../index';
import { useStore } from '../../store/store';
import { YesNoQuestionModal } from '../../components/Modal/YesNoQuestionModal';
import { SportCardCompany } from '../../enums/sportCardCompany.enums';
import { SportCardCompanies } from '../../constants/sportCard';
import { PathRoute } from '../../constants/pathRoute/Route';
import { SportCardTabsE } from './SportCardTabs/SportCardTabs';

interface ISportCardBoxProps {
  sportCard: ISportCardDto;
  onSave?: () => void;
}

export const SportCardBox = ({ sportCard, onSave }: ISportCardBoxProps) => {
  const { modalStore } = useStore();

  const deactivateSportCard = () => {
    modalStore.openModal(
      <YesNoQuestionModal
        buttonAgreeText={'Dezaktywuj'}
        onAgree={() =>
          agent.SportCard.deactivateCard(sportCard.id).then(() => {
            toast.success('Dezaktywowano pomyślnie');
            onSave?.();
          })
        }
        question={
          sportCard.mainCardId
            ? 'Czy na pewno chcesz dezaktywować kartę?'
            : 'Czy na pewno chcesz dezaktywować kartę? Spowoduje to również dezaktywację wszystkich kart podrzędnych.'
        }
      />,
      'lg',
      null,
    );
  };

  const getSportCompanyData = (company: SportCardCompany) => {
    return SportCardCompanies.find((el) => el.company === company);
  };

  return (
    <div className={twMerge('tw-mx-auto tw-w-full tw-max-w-md ')}>
      <div className={'tw-flex tw-justify-center'}>
        <div className={'tw-w-full tw-max-w-[400px]'}>
          <div className='tw-relative tw-flex tw-flex-col  tw-items-center tw-rounded-xl tw-shadow-[0_3px_12px_rgba(0,0,0,0.25)]'>
            <img
              alt={getSportCompanyData(sportCard.company)?.label}
              className='tw-mb tw-w-full tw-rounded-xl tw-bg-gradient-to-b tw-from-white tw-via-white tw-via-50% tw-to-[#bce4fc] tw-px-4 tw-py-8'
              src={getSportCompanyData(sportCard.company)?.logoUrl}
            />
            <div className={'tw-50 tw-absolute tw-inset-0'}></div>
          </div>
          <div className={'tw-mt-10'}>
            <h2 className='tw-mb-1 tw-text-xl tw-font-bold tw-text-nau-sea-green'>{`${sportCard.firstName} ${sportCard.lastName}`}</h2>
            <div className={'tw-text-lg tw-text-nau-sea-green'}>Abonament: {sportCard.subscriptionPlanName}</div>
          </div>

          <div className='tw-mt-10 tw-flex  tw-flex-col tw-gap-4'>
            <MyButton
              className={'tw-text-sm'}
              onClick={() => {
                history.push(PathRoute.SPORT_CARD + `?tab=${SportCardTabsE.OrderingView}&company=` + sportCard.company);
              }}
              variant={'primary'}>
              Chcę dodać kolejną osobę towarzyszącą lub dziecko
            </MyButton>
            {sportCard.sportCardStatus === SportCardStatus.Active && (
              <MyButton onClick={() => deactivateSportCard()} variant={'gray'}>
                Chcę zrezygnować z karty
              </MyButton>
            )}
          </div>
        </div>
      </div>

      {/* <div */}
      {/*   className={twMerge( */}
      {/*     ' tw-flex tw-items-center tw-p-4', */}
      {/*     sportCard.sportCardStatus === SportCardStatus.Active ? 'tw-bg-nau-green-light' : 'tw-bg-nau-gray', */}
      {/*   )}> */}
      {/*   <div className='tw-flex tw-flex-col'> */}
      {/*     <h2 className='tw-mb-1 tw-text-xl tw-font-semibold tw-text-white'>{`${sportCard.firstName} ${sportCard.lastName}`}</h2> */}
      {/*     <span className='tw-flex tw-items-center tw-gap-1 tw-font-normal tw-text-white'> */}
      {/*       <IdentificationIcon className={'tw-h-5 tw-w-5'} /> */}
      {/*       {sportCard.pesel} */}
      {/*     </span> */}
      {/*     <span className='tw-flex tw-items-center tw-gap-1 tw-font-normal tw-text-white'> */}
      {/*       <AtSymbolIcon className={'tw-h-5 tw-w-5'} /> */}
      {/*       {sportCard.email} */}
      {/*     </span> */}
      {/*     <span className='tw-flex tw-items-center tw-gap-1 tw-font-normal tw-text-white'> */}
      {/*       <PhoneIcon className={'tw-h-5 tw-w-5'} /> */}
      {/*       {sportCard.phoneNumber} */}
      {/*     </span> */}
      {/*   </div> */}
      {/* </div> */}

      {/* <div className={'tw-p-4'}> */}
      {/* Subscription Information */}
      {/* <div className='tw-mb-4'> */}
      {/*   <div className='tw-flex tw-items-center'> */}
      {/*     <span className='tw-text-sm tw-font-medium tw-text-gray-600'>Abonament: </span> */}
      {/*     <span className='tw-ml-2 tw-text-sm tw-text-gray-800'>{sportCard.subscriptionPlanName}</span> */}
      {/*   </div> */}
      {/* </div> */}

      {/* <div className='tw-mb-4'> */}
      {/*   <div className='tw-flex tw-items-center'> */}
      {/*     <span className='tw-text-sm tw-font-medium tw-text-gray-600'>Data aktywacji: </span> */}
      {/*     <span className='tw-ml-2 tw-text-sm tw-text-gray-800'>{sportCard.activationDateFormatted}</span> */}
      {/*   </div> */}
      {/* </div> */}

      {/* {sportCard.sportCardStatus === SportCardStatus.Resignation && ( */}
      {/*   <> */}
      {/*     <div className='tw-mb-4'> */}
      {/*       <div className='tw-flex tw-items-center'> */}
      {/*         <span className='tw-text-sm tw-font-medium tw-text-gray-600'>Status: </span> */}
      {/*         <span className='tw-ml-2 tw-text-sm tw-text-gray-800'>{sportCard.sportCardStatusName}</span> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*     <div className='tw-mb-4'> */}
      {/*       <div className='tw-flex tw-items-center'> */}
      {/*         <span className='tw-text-sm tw-font-medium tw-text-gray-600'>Data rezygnacji: </span> */}
      {/*         <span className='tw-ml-2 tw-text-sm tw-text-gray-800'>{sportCard.resignationDateFormatted}</span> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   </> */}
      {/* )} */}

      {/* Creation Date */}
      {/* <div className='tw-mb-4'> */}
      {/*   <div className='tw-flex tw-items-center'> */}
      {/*     <span className='tw-text-sm tw-font-medium tw-text-gray-600'>Data utworzenia: </span> */}
      {/*     <span className='tw-ml-2 tw-text-sm tw-text-gray-800'>{sportCard.createdOnFormatted}</span> */}
      {/*   </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};
