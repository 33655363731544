import React from 'react';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import MyButton from '../../components/Buttons/MyButton/MyButton';
import { InstitutionSearchByAddressForm } from '../../components/Institution/InstitutionSearchByAddressForm';
import agent from '../../api/agent';
import { SportCardCompany } from '../../enums/sportCardCompany.enums';
import { useStore } from '../../store/store';
import { InstitutionNotAllowedToGetSportCardsInfo } from './InstitutionNotAllowedToGetSportCardsInfo';
import { history } from '../../index';
import { PathRoute } from '../../constants/pathRoute/Route';

export interface ISopInstitutionData {
  institutionSopId: number;
  institutionName: string;
  institutionRegon: string;
}
interface ISportCardApplicationFormProps {
  onSave?: (sopInstitutionData: ISopInstitutionData) => void;
  sportCardCompany: SportCardCompany;
}
export const OrderSportCardInstitutionForm = (props: ISportCardApplicationFormProps) => {
  const { modalStore } = useStore();

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          institutionSopId: undefined,
          city: '',
          zipCode: '',
          street: '',
          institutionRegon: '',
          institutionName: '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (!values.institutionSopId) {
              toast.error('Wybierz placówkę');
              return;
            }

            const isCardAvailable = await agent.SportCard.checkSportCardIsAvailableInInstitution(
              props.sportCardCompany,
              values.institutionSopId,
            );

            if (isCardAvailable) {
              props.onSave?.({
                institutionSopId: values.institutionSopId,
                institutionName: values.institutionName,
                institutionRegon: values.institutionRegon,
              });
            } else {
              history.push(PathRoute.SPORT_CARD);
              modalStore.openModal(<InstitutionNotAllowedToGetSportCardsInfo />, 'lg', () => <></>);
            }
          } catch (error) {
            console.error('Error sending request', error);
          } finally {
            setSubmitting(false);
          }
        }}>
        {({ isSubmitting, values }) => (
          <Form className='tw-w-full'>
            <div className='tw-mb-8 tw-text-center tw-text-xl tw-font-semibold tw-text-nau-green-dark'>
              Podaj nazwę placówki, w celu weryfikacji usługi
            </div>
            <div className='tw-mb-4 tw-flex tw-flex-col tw-gap-4'>
              <InstitutionSearchByAddressForm
                cityFormikName={'city'}
                institutionFullNameFormikName={'institutionName'}
                institutionSopIdFormikName={'institutionSopId'}
                postCodeFormikName={'zipCode'}
                regonFormikName={'institutionRegon'}
                streetFormikName={'street'}
              />
            </div>

            <div className='tw-mb-4'>
              <MyButton
                className='tw-mt-4 tw-w-full'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                type='submit'
                variant={'greenDark'}>
                Dalej
              </MyButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
