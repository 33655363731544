import { Link } from 'react-router-dom';
import { Button, Container, Image } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { PathRoute } from '../../../constants/pathRoute/Route';

export default function NotFound() {
  return (
    <Container>
      <Image alt='logo' className='logo' src='/assets/logo.svg' style={{ marginBottom: '40px' }} />
      <div className='d-flex justify-content-center'>
        <h2>
          <Search />
          <span style={{ paddingLeft: '20px', fontSize: '24px' }}>Nie znaleziono strony o podanym adresie</span>
        </h2>
      </div>
      <div className='d-flex justify-content-center'>
        <Link to={PathRoute.PUSTY}>
          <Button size='lg' variant='outline-light'>
            Wróć do strony głównej
          </Button>
        </Link>
      </div>
    </Container>
  );
}
