import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import ApprovalsForm from '../../components/ApprovalsForm/ApprovalsForm';
import { Benefit } from '../../enums/benefits.enums';
import { useStore } from '../../store/store';
import SportCardApplicationForm from './SportCardApplicationForm';
import { SportCardCompany } from '../../enums/sportCardCompany.enums';
import { ISopInstitutionData, OrderSportCardInstitutionForm } from './OrderSportCardInstitutionForm';
import LoadingComponent from '../../components/Loadings/LoadingComponent/LoadingComponent';
import { openLink } from '../../utils/linkUtils';

interface IOrderSportCardLayoutProps {
  sportCardCompany: SportCardCompany;
}
export default observer((props: IOrderSportCardLayoutProps) => {
  const { userStore } = useStore();

  const [hasBenefitApprovals, setHasBenefitApprovals] = useState<boolean>(true);
  const [areApprovalsLoading, setAreApprovalsLoading] = useState<boolean>(true);
  const [sopInstitution, setSopInstitution] = useState<ISopInstitutionData | undefined>(undefined);

  useEffect(() => {
    setAreApprovalsLoading(true);
    userStore
      .hasRequiredApprovalsForBenefit(Benefit.SportCard)
      .then((resp) => {
        setHasBenefitApprovals(resp);
      })
      .finally(() => {
        setAreApprovalsLoading(false);
      });
  }, []);

  return (
    <div className={'tw-relative'}>
      {areApprovalsLoading && <LoadingComponent />}
      {hasBenefitApprovals ? (
        <>
          {sopInstitution ? (
            <>
              {props.sportCardCompany === SportCardCompany.PZU && (
                <SportCardApplicationForm sopInstitution={sopInstitution} sportCardCompany={props.sportCardCompany} />
              )}
              {props.sportCardCompany === SportCardCompany.MultiSport && (
                <div>
                  Oto link do strony: <a href='https://www.multisport.pl/'>MultiSport</a>
                </div>
              )}
              {props.sportCardCompany === SportCardCompany.Medicover && (
                <div>
                  <div className='tw-my-6 tw-text-center tw-text-lg tw-font-semibold tw-text-nau-green-dark'>
                    Aby dokończyć rejestrację, prosimy przejść pod poniższy link:
                    <div
                      className='tw-text-md tw-my-4 tw-cursor-pointer tw-break-words tw-font-normal tw-text-blue-500'
                      onClick={() => openLink('https://medicoversport.pl/pakiety/nauczycielskaagencjaubezpieczeniowa')}>
                      https://medicoversport.pl/pakiety/nauczycielskaagencjaubezpieczeniowa
                    </div>
                    <div className={'tw-font-normal'}>
                      Na stronie rejestracji należy wpisać kod:{' '}
                      <span className={'tw-font-semibold'}>NauczycielskaagencjaubezpieczeniowaMS2024</span>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <OrderSportCardInstitutionForm
                onSave={(sopInstitution) => {
                  setSopInstitution(sopInstitution);
                }}
                sportCardCompany={props.sportCardCompany}
              />
            </>
          )}
        </>
      ) : (
        <div>
          <div className={'tw-text-md tw-mb-4 tw-font-bold tw-text-nau-green-dark'}>
            Zaakceptuj zgody marketingowe, aby przejść dalej
          </div>
          <ApprovalsForm allRequired={true} benefitId={Benefit.SportCard} hideHeader={true} />
        </div>
      )}
    </div>
  );
});
