import React from 'react';
import CustomerDashboard from '../../CustomerDashboard/CustomerDashboard';
import { useStore } from '../../../store/store';
import SubpageHeader from '../../../components/SubpageHeader/SubpageHeader';
import { Link } from 'react-router-dom';
import { PathRoute } from '../../../constants/pathRoute/Route';

import { Image } from 'react-bootstrap';
import PhoneIcon from '../../../components/Icons/PhoneIcon';
import { UserActivityCodeE } from '../../../enums/UserActivityCode.enums';
import { openLink } from '../../../utils/linkUtils';
import { useSwrAgent } from '../../../api/useSwrAgent';
import { UserPromotionE } from '../../../enums/userPromotion.enums';
import LoadingComponent from '../../../components/Loadings/LoadingComponent/LoadingComponent';

export const PhoneSubscriptionPage = () => {
  const { userStore } = useStore();
  const { data: userMarketingData, isLoading } = useSwrAgent().Users.GetUserMarketingData();
  const hideSimNau =
    userMarketingData &&
    userMarketingData.userPromotionIds?.some((id) => id === UserPromotionE.CinemaVouchersSchoolStuff);

  return (
    <CustomerDashboard>
      <LoadingComponent content='Ładowanie...' visible={isLoading} />
      <div className='tw-mx-auto md:tw-w-1/2'>
        <SubpageHeader
          headerClassName={'tw-text-3xl'}
          iconComponent={<PhoneIcon className={'sm:tw-mr-4'} />}
          iconHeight={35}
          title={'Abonament komórkowy'}
        />
        <div className='tw-w-full tw-rounded-b-lg tw-bg-white tw-p-6 tw-text-nau-green-dark tw-shadow-md'>
          <div className={'tw-flex tw-flex-col tw-items-center tw-gap-4'}>
            <div
              className={'tw-cursor-pointer'}
              onClick={() => {
                userStore.registerUserActivity(UserActivityCodeE.OpenNauMobileLink);
                openLink(PathRoute.NAU_MOBILE_SITE);
              }}>
              <Image className={'tw-bg-white'} src='/assets/nauMobile/nauMobileIcon.svg' />
            </div>
            {!hideSimNau && (
              <Link className={''} to={PathRoute.SIM_NAU}>
                <Image className={'tw-bg-white'} src='/assets/nauMobile/simNauIcon.svg' />
              </Link>
            )}
          </div>
        </div>
      </div>
    </CustomerDashboard>
  );
};
